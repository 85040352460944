import { getCurrentOrg, getOrgLastUpdated, getOrgs, getOrgsLastUpdated } from 'src/selectors/organizations';
import { getCurrentOrgId, getCurrentAppId } from 'src/selectors/current_app';
import { getCurrentUserId } from 'src/selectors/user';
import { DruidService } from 'src/services/druid-service';
import { MongoInteractionService } from 'src/services';
import { isEmpty } from 'ramda';

// Create an Organization
export const CREATE_ORGANIZATION_PENDING = 'CREATE_ORGANIZATION_PENDING';
export const createOrganizationPending = (data) => ({
  type: CREATE_ORGANIZATION_PENDING,
  payload: data,
  meta: { data },
});
export const CREATE_ORGANIZATION_SUCCESS = 'CREATE_ORGANIZATION_SUCCESS';
export const createOrganizationSuccess = (data, json) => ({
  type: CREATE_ORGANIZATION_SUCCESS,
  payload: json,
  meta: { data },
});
export const CREATE_ORGANIZATION_FAILURE = 'CREATE_ORGANIZATION_FAILURE';
export const createOrganizationFailure = (data, error) => ({
  type: CREATE_ORGANIZATION_FAILURE,
  payload: error,
  error: true,
  meta: { data },
});
export const createOrganization =
  (data) =>
  (dispatch, getState, { api }) => {
    dispatch(createOrganizationPending(data));
    if (!data) {
      const message = 'Missing required parameter: data';
      dispatch(createOrganizationFailure(data, message));
      return Promise.reject(new Error(message));
    }
    return api
      .createOrganization(data)
      .then((json) => dispatch(createOrganizationSuccess(data, json)))
      .catch((ex) => dispatch(createOrganizationFailure(data, ex.message)));
  };

// Fetch single organization (this is necessary to get the billing contact for the org)
export const FETCH_ORGANIZATION_PENDING = 'FETCH_ORGANIZATION_PENDING';
export const fetchOrganizationPending = (orgId) => ({
  type: FETCH_ORGANIZATION_PENDING,
  payload: { orgId },
  meta: { orgId },
});
export const FETCH_ORGANIZATION_SUCCESS = 'FETCH_ORGANIZATION_SUCCESS';
export const fetchOrganizationSuccess = (orgId, json) => ({
  type: FETCH_ORGANIZATION_SUCCESS,
  payload: json,
  meta: {
    orgId,
    lastUpdated: Date.now(),
  },
});
export const FETCH_ORGANIZATION_FAILURE = 'FETCH_ORGANIZATION_FAILURE';
export const fetchOrganizationFailure = (orgId, error) => ({
  type: FETCH_ORGANIZATION_FAILURE,
  payload: error,
  error: true,
  meta: { orgId },
});

export const fetchOrganization =
  (orgId) =>
  (dispatch, getState, { api }) => {
    const state = getState();
    orgId = orgId || getCurrentOrgId(state);
    dispatch(fetchOrganizationPending(orgId));
    if (!orgId) {
      const message = 'Missing required parameter: orgId';
      dispatch(fetchOrganizationFailure(orgId, message));
      return Promise.reject(new Error(message));
    }

    const currentOrg = getCurrentOrg(state);
    if (currentOrg.id === orgId) {
      const CACHE_TIMEOUT = 300000;
      const lastUpdated = getOrgLastUpdated(state);
      const cacheAge = Date.now() - lastUpdated;
      if (cacheAge < CACHE_TIMEOUT) {
        dispatch(fetchOrganizationSuccess(orgId, currentOrg));
        return Promise.resolve(currentOrg);
      }
    }

    return api
      .fetchOrganization(orgId)
      .then((json) => dispatch(fetchOrganizationSuccess(orgId, json)))
      .catch((ex) => dispatch(fetchOrganizationFailure(orgId, ex.message)));
  };

// Members in Org
export const REQUEST_ORGANIZATION_MEMBERS = 'REQUEST_ORGANIZATION_MEMBERS';
export const fetchOrganizationMembersPending = (userId, orgId) => ({
  type: REQUEST_ORGANIZATION_MEMBERS,
  payload: { userId, orgId },
  meta: { userId, orgId },
});
export const RECEIVE_ORGANIZATION_MEMBERS = 'RECEIVE_ORGANIZATION_MEMBERS';
export const fetchOrganizationMembersSuccess = (userId, orgId, json) => ({
  type: RECEIVE_ORGANIZATION_MEMBERS,
  payload: json,
  meta: { userId, orgId },
});
export const FETCH_ORGANIZATION_MEMBERS_FAILURE = 'FETCH_ORGANIZATION_MEMBERS_FAILURE';
export const fetchOrganizationMembersFailure = (userId, orgId, error) => ({
  type: FETCH_ORGANIZATION_MEMBERS_FAILURE,
  payload: error,
  error: true,
  meta: { userId, orgId },
});
export const fetchOrganizationMembers =
  (userId, orgId) =>
  (dispatch, getState, { api }) => {
    dispatch(fetchOrganizationMembersPending(userId, orgId));
    if (!userId) {
      const message = 'Missing required parameter: userId';
      dispatch(fetchOrganizationMembersFailure(userId, orgId, message));
      return Promise.reject(new Error(message));
    }
    if (!orgId) {
      const message = 'Missing required parameter: orgId';
      dispatch(fetchOrganizationMembersFailure(userId, orgId, message));
      return Promise.reject(new Error(message));
    }
    return api
      .fetchOrganizationMembers(userId, orgId)
      .then((json) => dispatch(fetchOrganizationMembersSuccess(userId, orgId, json)))
      .catch((ex) => dispatch(fetchOrganizationMembersFailure(userId, orgId, ex.message)));
  };

// Apps for Org
export const REQUEST_APPS_FOR_ORG = 'REQUEST_APPS_FOR_ORG';
export const fetchOrganizationAppsPending = (userId, orgId, currentAppId) => ({
  type: REQUEST_APPS_FOR_ORG,
  payload: { userId, orgId, currentAppId },
  meta: { userId, orgId, currentAppId },
});
export const RECEIVE_APPS_FOR_ORG = 'RECEIVE_APPS_FOR_ORG';
export const fetchOrganizationAppsSuccess = (userId, orgId, currentAppId, json) => ({
  type: RECEIVE_APPS_FOR_ORG,
  payload: json,
  meta: {
    userId,
    orgId,
    currentAppId,
    lastUpdated: Date.now(),
  },
});
export const FETCH_ORGANIZATION_APPS_FAILURE = 'FETCH_ORGANIZATION_APPS_FAILURE';
export const fetchOrganizationAppsFailure = (userId, orgId, currentAppId, error) => ({
  type: FETCH_ORGANIZATION_APPS_FAILURE,
  payload: error,
  meta: { userId, orgId, currentAppId },
  error: true,
});

const enrichOrgAppsWithDataFromMIS = async (initAppsData) => {
  if (!initAppsData || isEmpty(initAppsData.length)) {
    return [];
  }

  const appIdInitDataMap = new Map();
  const appIds = initAppsData.map((app) => app.id);

  try {
    const extraAppsData = await MongoInteractionService.getListOfAppsData(appIds);
    extraAppsData.forEach((app) => appIdInitDataMap.set(app.id, app));

    return initAppsData.map((app) => ({ ...app, ...(appIdInitDataMap.get(app.id) || {}) }));
  } catch (e) {
    throw Error(`failed to enrich org apps data error: ${e}`);
  }
};

export const fetchOrganizationApps =
  (userId, orgId) =>
  (dispatch, getState, { api }) => {
    const state = getState();
    const currentAppId = getCurrentAppId(state);

    dispatch(fetchOrganizationAppsPending(userId, orgId, currentAppId));
    if (!userId) {
      const message = 'Missing required parameter: userId';
      dispatch(fetchOrganizationAppsFailure(userId, orgId, currentAppId, message));
      return Promise.reject(new Error(message));
    }
    if (!orgId) {
      const message = 'Missing required parameter: orgId';
      dispatch(fetchOrganizationAppsFailure(userId, orgId, currentAppId, message));
      return Promise.reject(new Error(message));
    }

    // Short circuit API call if cached orgs are fresh.
    const orgs = getOrgs(state);
    const org = orgs[orgId] || {};
    const CACHE_TIMEOUT = 300000;
    const lastUpdated = org.lastUpdated || 0;
    const cacheAge = Date.now() - lastUpdated;
    const apps = org.apps || [];
    if (apps.length > 0 && cacheAge < CACHE_TIMEOUT) {
      dispatch(fetchOrganizationAppsSuccess(userId, orgId, currentAppId, apps));
      return Promise.resolve(apps);
    }
    return api
      .fetchOrganizationApps(userId, orgId, currentAppId)
      .then(enrichOrgAppsWithDataFromMIS)
      .then((json) => dispatch(fetchOrganizationAppsSuccess(userId, orgId, currentAppId, json)))
      .catch((ex) => dispatch(fetchOrganizationAppsFailure(userId, orgId, currentAppId, ex.message)));
  };

export const fetchCurrentOrganizationApps = () => (dispatch, getState) => {
  const state = getState();
  const userId = getCurrentUserId(state);
  const orgId = getCurrentOrgId(state);
  const appId = getCurrentAppId(state);
  dispatch(fetchOrganizationApps(userId, orgId, appId));
};

// Orgs
export const FETCH_ORGANIZATIONS_PENDING = 'FETCH_ORGANIZATIONS_PENDING';
export const fetchOrganizationsPending = (userId) => ({
  type: FETCH_ORGANIZATIONS_PENDING,
  payload: { userId },
  meta: { userId },
});
export const FETCH_ORGANIZATIONS_SUCCESS = 'FETCH_ORGANIZATIONS_SUCCESS';
export const fetchOrganizationsSuccess = (userId, json) => ({
  type: FETCH_ORGANIZATIONS_SUCCESS,
  payload: json,
  meta: {
    userId,
    lastUpdated: Date.now(),
  },
});
export const FETCH_ORGANIZATIONS_FAILURE = 'FETCH_ORGANIZATIONS_FAILURE';
export const fetchOrganizationsFailure = (userId, error) => ({
  type: FETCH_ORGANIZATIONS_FAILURE,
  payload: error,
  meta: { userId },
  error: true,
});
export const fetchOrganizations = (userId) => (dispatch, getState, { api }) => {
  const state = getState();
  if (!userId) {
    const message = 'Missing required parameter: userId';
    dispatch(fetchOrganizationsFailure(userId, message));
    return Promise.reject(new Error(message));
  }

  const orgs = getOrgs(state);
  const CACHE_TIMEOUT = 300000;
  const lastUpdated = getOrgsLastUpdated(state);
  const cacheAge = Date.now() - lastUpdated;
  if (Object.keys(orgs).length > 0 && cacheAge < CACHE_TIMEOUT) {
    return Promise.resolve(orgs);
  }

  // NOTE: We set pending here to avoid setting the loading flag to true & get stuck
  // unable to set it to false as we change data structure from the original fetch
  // by having it higher up in the function.
  dispatch(fetchOrganizationsPending(userId));
  return api
    .fetchOrganizations(userId)
    .then((json) => dispatch(fetchOrganizationsSuccess(userId, json)))
    .then((json) => {
      const orgIds = json.payload.map((org) => org.id);
      const promises = orgIds.map((orgId) => dispatch(fetchOrganizationApps(userId, orgId)));
      return Promise.all(promises);
    })
    .catch((ex) => dispatch(fetchOrganizationsFailure(userId, ex.message)));
};

// Delete an Organization
export const DELETE_ORGANIZATION_PENDING = 'DELETE_ORGANIZATION_PENDING';
export const deleteOrganizationPending = (orgId, confirmation) => ({
  type: DELETE_ORGANIZATION_PENDING,
  payload: orgId,
  meta: { orgId, confirmation },
});
export const DELETE_ORGANIZATION_SUCCESS = 'DELETE_ORGANIZATION_SUCCESS';
export const deleteOrganizationSuccess = (orgId, confirmation, json) => ({
  type: DELETE_ORGANIZATION_SUCCESS,
  payload: json,
  meta: { orgId, confirmation },
});
export const DELETE_ORGANIZATION_FAILURE = 'DELETE_ORGANIZATION_FAILURE';
export const deleteOrganizationFailure = (orgId, confirmation, error) => ({
  type: DELETE_ORGANIZATION_FAILURE,
  payload: error,
  error: true,
  meta: { orgId, confirmation },
});
export const deleteOrganization =
  (orgId, confirmation) =>
    (dispatch, getState, { api }) => {
      dispatch(deleteOrganizationPending(orgId, confirmation));
      if (!orgId) {
        const message = 'Missing required parameter: orgId';
        dispatch(deleteOrganizationFailure(orgId, confirmation, message));
        return Promise.reject(new Error(message));
      }
      if (!confirmation) {
        const message = 'Missing required parameter: confirmation';
        dispatch(deleteOrganizationFailure(orgId, confirmation, message));
        return Promise.reject(new Error(message));
      }
      return api
        .deleteOrganization(orgId, confirmation)
        .then((json) => dispatch(deleteOrganizationSuccess(orgId, confirmation, json)))
        .catch((ex) => dispatch(deleteOrganizationFailure(orgId, confirmation, ex.message)));
    };

// Update an Organization
export const UPDATE_ORGANIZATION_PENDING = 'UPDATE_ORGANIZATION_PENDING';
export const updateOrganizationPending = (orgId, body) => ({
  type: UPDATE_ORGANIZATION_PENDING,
  payload: orgId,
  meta: { orgId, body },
});
export const UPDATE_ORGANIZATION_SUCCESS = 'UPDATE_ORGANIZATION_SUCCESS';
export const updateOrganizationSuccess = (orgId, body, json) => ({
  type: UPDATE_ORGANIZATION_SUCCESS,
  payload: json,
  meta: {
    orgId,
    body,
    lastUpdated: Date.now(),
  },
});
export const UPDATE_ORGANIZATION_FAILURE = 'UPDATE_ORGANIZATION_FAILURE';
export const updateOrganizationFailure = (orgId, body, error) => ({
  type: UPDATE_ORGANIZATION_FAILURE,
  payload: error,
  error: true,
  meta: { orgId, body },
});
export const updateOrganization =
  (orgId, body) =>
    (dispatch, getState, { api }) => {
      dispatch(updateOrganizationPending(orgId, body));
      if (!orgId) {
        const message = 'Missing required parameter: orgId';
        dispatch(updateOrganizationFailure(orgId, body, message));
        return Promise.reject(new Error(message));
      }
      if (!body) {
        const message = 'Missing required parameter: body';
        dispatch(updateOrganizationFailure(orgId, body, message));
        return Promise.reject(new Error(message));
      }
      return api
        .updateOrganization(orgId, body)
        .then((json) => dispatch(updateOrganizationSuccess(orgId, body, json)))
        .catch((ex) => dispatch(updateOrganizationFailure(orgId, body, ex.message)));
    };

export const FETCH_SDK_VERSION_REACH_BY_APPS_PENDING = 'FETCH_SDK_VERSION_REACH_BY_APPS_PENDING';
export const fetchSdkVersionReachByAppsPending = ({ orgId, appIds, sdkVersion }) => ({
  type: FETCH_SDK_VERSION_REACH_BY_APPS_PENDING,
  payload: { appIds },
  meta: { orgId, sdkVersion },
});
export const FETCH_SDK_VERSION_REACH_BY_APPS_SUCCESS = 'FETCH_SDK_VERSION_REACH_BY_APPS_SUCCESS';
export const fetchSdkVersionReachByAppsSuccess = ({ orgId, sdkVersion, json }) => ({
  type: FETCH_SDK_VERSION_REACH_BY_APPS_SUCCESS,
  payload: json,
  meta: { orgId, sdkVersion },
});
export const FETCH_SDK_VERSION_REACH_BY_APPS_FAILURE = 'FETCH_SDK_VERSION_REACH_BY_APPS_FAILURE';
export const fetchSdkVersionReachByAppsFailure = ({ orgId, appIds, sdkVersion, error }) => ({
  type: FETCH_SDK_VERSION_REACH_BY_APPS_FAILURE,
  payload: error,
  error: true,
  meta: { orgId, appIds, sdkVersion },
});
export const fetchSdkVersionReachByApps = (appIds, sdkVersion) => (dispatch, getState) => {
  const state = getState();
  const orgId = getCurrentOrgId(state);
  dispatch(fetchSdkVersionReachByAppsPending({ orgId, appIds, sdkVersion }));
  return DruidService.fetchMultipleReachByVersion(orgId, appIds, sdkVersion)
    .then((json) => dispatch(fetchSdkVersionReachByAppsSuccess({ orgId, sdkVersion, json })))
    .catch((error) => dispatch(fetchSdkVersionReachByAppsFailure({ orgId, appIds, sdkVersion, error })));
};
