import React, { FormEvent, useMemo } from 'react';
import { GtmID } from 'src/types/google-tag-manager';
import templates from 'src/utils/jsons/survey_templates.json';
import templatesV12 from 'src/utils/jsons/survey_v12_templates.json';

// Component that renders a dropdown with all the survey templates.

import './survey_template_select.scss';

type TemplateName = keyof typeof templates;

export interface SurveyTemplateSelectProps {
  defaultOptionText?: string;
  fromTemplate?: string; // TemplateName;
  onChange: (surveyType: TemplateName) => void;
  isV12?: boolean;
  defaultOptionTextJourneySurveys?: string;
}

export { templates, templatesV12 };

export const buildSurveyTemplateUrl = (appId: string, templateName: string, isV12 = false) =>
  `/apps/${appId}/${isV12 ? 'surveys_2.0' : 'surveys'}/new?template=${encodeURIComponent(templateName)}`;

export const SurveyTemplateSelect: React.FC<SurveyTemplateSelectProps> = ({
  fromTemplate,
  isV12 = false,
  ...props
}) => {
  const handleOnChange = (event: FormEvent<HTMLSelectElement>) =>
    props.onChange(event.currentTarget.value as TemplateName);
  const templateItems: any = isV12 ? templatesV12 : templates;
  const selected = useMemo(
    () => Object.keys(templateItems).find((key: TemplateName) => templateItems[key].from_template === fromTemplate),
    [fromTemplate],
  );

  return (
    <select className={'survey-template-select'} value={selected || ''} onChange={handleOnChange}>
      <option disabled value="">
        {props.defaultOptionText}
      </option>
      {Object.keys(templateItems).map((surveyType: TemplateName) => (
        <option key={surveyType} value={surveyType} gtm-id={GtmID.InteractionSurveyTemplate}>
          {surveyType}
        </option>
      ))}
    </select>
  );
};

const defaultProps: Partial<SurveyTemplateSelectProps> = {
  defaultOptionText: 'Create from template...',
  defaultOptionTextJourneySurveys: 'Templates not yet available for New SDK ',
};

SurveyTemplateSelect.defaultProps = defaultProps;

export default SurveyTemplateSelect;
